<template>
  <div>
    <v-simple-table v-if="overview">
      <template v-slot:default>
        <tbody>
          <tr v-if="overview.organization">
            <td>{{ $helpers.string.capitalizeI18N("member-since") }}</td>
            <td>
              {{
      $helpers.date.getFullDateTimeDisplay(
        overview.organization.createdAt
      )
    }}
            </td>
          </tr>
          <tr v-if="overview.lessons && overview.lessons.nb">
            <td>
              {{
      $helpers.string.capitalize(
        $tc("class.class", overview.lessons.nb)
      )
    }}
            </td>
            <td>
              <span v-if="overview.lessons.nb" class="mr-2">{{
      overview.lessons.nb
    }}</span>
              <!-- <v-chip small v-if="overview.lessons.last" class="mr-2"
                ><v-icon small class="mr-2">access_time</v-icon>
                {{ overview.lessons.last.name }} {{ "le" }}
                {{
                  $helpers.date.getFullDateTimeDisplay(
                    overview.lessons.last.created_at
                  )
                }}</v-chip
              > -->
            </td>
          </tr>
          <tr v-if="overview.clients && overview.clients.nb">
            <td>
              {{
      $helpers.string.capitalize(
        $tc("client.client", overview.clients.nb)
      )
    }}
            </td>
            <td>
              <span v-if="overview.clients.nb" class="mr-2">{{
      overview.clients.nb
    }}</span>
              <!-- <v-chip small v-if="overview.clients.last" class="mr-2"
                ><v-icon small class="mr-2">access_time</v-icon>
                {{ overview.clients.last.firstName }}
                {{ overview.clients.last.lastName }} {{ "le" }}
                {{
                  $helpers.date.getFullDateTimeDisplay(
                    overview.clients.last.created_at
                  )
                }}</v-chip
              > -->
            </td>
          </tr>
          <tr v-if="overview.memberships && overview.memberships.nb">
            <td>{{ $helpers.string.capitalizeI18N("memberships") }}</td>
            <td>
              <span v-if="overview.memberships.nb" class="mr-2">{{
      overview.memberships.nb
    }}</span>
              <!-- <v-chip
                small
                v-if="
                  overview.memberships.last && overview.memberships.last.client
                "
                class="mr-2"
                ><v-icon small class="mr-2">access_time</v-icon>
                {{ overview.memberships.last.client.firstName }}
                {{ overview.memberships.last.client.lastName }} {{ "le" }}
                {{
                  $helpers.date.getFullDateTimeDisplay(
                    overview.memberships.last.created_at
                  )
                }}</v-chip
              > -->
            </td>
          </tr>
          <tr v-if="url">
            <td>{{ $helpers.string.capitalizeI18N("url-planning") }}</td>
            <td>
              <span class="mr-2">www.monplanning.be/{{ url }}</span>
              <v-btn @click="copyToClipboard(`https://www.monplanning.be/${url}`)" icon color="primary">
                <v-icon small>mdi-content-copy</v-icon></v-btn>
              <v-btn :to="'/' + url" icon color="primary">
                <v-icon small>mdi-open-in-new</v-icon></v-btn>
              <BillPlanRequired class="ml-2" :billPlan="billPlan" />
            </td>
          </tr>
          <tr v-if="url">
            <td>{{ $helpers.string.capitalizeI18N("url-register") }}</td>
            <td>
              <span class="mr-2">www.monplanning.be/{{ url }}/register</span>
              <v-btn @click="copyToClipboard(`https://www.monplanning.be/${url}/register`)" icon color="primary">
                <v-icon small>mdi-content-copy</v-icon></v-btn>
              <v-btn :to="'/' + url + '/register'" icon color="primary"><v-icon small>mdi-open-in-new</v-icon></v-btn>
              <BillPlanRequired class="ml-2" :billPlan="billPlan" />
            </td>
          </tr>
          <tr>
            <td>Clé de votre compte mollie</td>
            <td>
              <span v-if="mollieApiKey">{{ mollieApiKey }}</span>
              <span v-else>Aucune<v-btn class="ml-2" plain href="https://www.mollie.com/" link target="_blank">Créer mon
                  compte Mollie<v-icon small right>mdi-open-in-new</v-icon></v-btn></span>
              <BillPlanRequired class="ml-2" :billPlan="billPlan" />
            </td>
          </tr>
          <tr>
            <td>Code parrainage</td>
            <td>{{ referralCode }}</td>
          </tr>
          <tr v-if="referralName">
            <td>Votre parrain</td>
            <td>{{ referralName }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card flat>
      <v-card-title>Plan tarifaire</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-alert outlined :type="boxType" prominent border="left">
              <v-row align="center">
                <v-col class="grow">
                  <div v-if="billPlan">
                    Plan tarifaire actuel : {{ $t("bill-plans." + billPlan) }}
                  </div>
                  <div>
                    Vous avez opté pour un paiement
                    {{
      billPlanPeriodicity == "monthly"
        ? $t("monthly")
        : $t("annually")
    }}
                  </div>
                  <div v-if="freeTrialValidityDateEnd">
                    Votre essai gratuit est valide jusqu'au
                    <span class="font-weight-bold">{{
      $helpers.date.getDateDisplay(freeTrialValidityDateEnd)
    }}</span>
                  </div>
                  <div v-else-if="validityDateEnd">
                    Votre offre est valide jusqu'au
                    <span class="font-weight-bold">{{
      $helpers.date.getDateDisplay(validityDateEnd)
    }}</span>
                  </div>
                  <div v-else>Votre offre n'a pas de date de fin.</div>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>

          <v-col>
            <v-alert outlined color="primary" border="left">
              <v-card flat>
                <v-card-title>Prolonger la validité</v-card-title>
                <v-card-text>
                  <v-container v-if="simulatedPrice">
                    <v-row justify="center">
                      <v-radio-group v-model="currentBillPlanPeriodicity" row>
                        <v-col class="text-center">
                          <v-chip color="orange lighten-4" :outlined="currentBillPlanPeriodicity == 'annually'" link
                            @click="currentBillPlanPeriodicity = 'monthly'"><v-radio
                              :label="$helpers.string.capitalizeI18N('monthly')" value="monthly"></v-radio>
                          </v-chip>
                        </v-col>
                        <v-col class="text-center">
                          <v-chip class="ml-6 pr-0" color="orange lighten-4"
                            :outlined="currentBillPlanPeriodicity == 'monthly'" link
                            @click="currentBillPlanPeriodicity = 'annually'"><v-radio :label="$helpers.string.capitalizeI18N('annually')
      " value="annually"></v-radio>
                            <v-chip color="deep-orange lighten-2" class="font-weight-bold text-body-1 white--text" link
                              @click="currentBillPlanPeriodicity = 'annually'">-10%</v-chip>
                          </v-chip>
                        </v-col>
                      </v-radio-group>
                      <!-- <v-radio-group v-model="currentBillPlanPeriodicity" row>
                        <v-radio
                          :label="$helpers.string.capitalizeI18N('monthly')"
                          value="monthly"
                        ></v-radio>
                        <v-radio
                          :label="
                            $helpers.string.capitalizeI18N('annually') +
                            ' (-10%)'
                          "
                          value="annually"
                        ></v-radio>
                      </v-radio-group> -->
                    </v-row>
                    <v-row>
                      <v-col>Offre <span class="font-weight-bold">{{ $t('bill-plans.' + currentBillPlan)
                          }}</span></v-col>
                      <v-col class="text-right">{{ simulatedPrice.billPlan }} € HTVA /
                        {{
      simulatedPrice.periodicity == "monthly"
        ? $t("month")
        : $t("year")
    }}
                      </v-col>
                    </v-row>
                    <v-row v-if="users.length - 1 >= 1">
                      <v-col>Utilisateurs supplémentaires ({{
      users.length - 1
    }})</v-col>
                      <v-col class="text-right">{{ simulatedPrice.users }} € HTVA /
                        {{
      simulatedPrice.periodicity == "monthly"
        ? $t("month")
        : $t("year")
    }}</v-col>
                    </v-row>
                    <v-row>
                      <v-divider />
                    </v-row>
                    <v-row>
                      <v-col>Total</v-col>
                      <v-col class="text-right"><span class="font-weight-bold ml-1 mr-1">{{ simulatedPrice.total }} €
                          TVAC</span>
                        /
                        {{
      simulatedPrice.periodicity == "monthly"
        ? $t("month")
        : $t("year")
    }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>Valide jusqu'au</v-col>
                      <v-col class="text-right font-weight-bold">{{
        $helpers.date.getDateDisplay(
          simulatedPrice.newValidityDateEnd
        )
      }}
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-btn @click="extendValidity()" color="primary" :loading="loadingExtend">{{ $t("extend")
                        }}<v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-alert>
          </v-col>
          <!-- <v-col>
            <v-alert outlined color="primary" border="left">
              <v-card flat>
                <v-card-title>Changer le plan tarifaire</v-card-title>
                <v-card-text>
                  <v-container v-if="simulatedPrice">
                    <v-row justify="center">
                      <v-btn
                        :to="$helpers.router.getPublicLink('contact')"
                        outlined
                        color="primary"
                        >Contactez-nous</v-btn
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-alert>
          </v-col> -->

          <!-- <v-col>
            <v-alert outlined color="primary" border="left">
              <v-tabs fixed-tabs v-model="tab">
                <v-tab key="ExtendValidity" href="#ExtendValidity">
                  prolonger la validité
                </v-tab>
                <v-tab key="ChangePricing" href="#ChangePricing">
                  Changer le plan tarifaire
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="ExtendValidity" value="ExtendValidity">
                  <v-card flat>
                    <v-card-text>
                      <v-container v-if="simulatedPrice">
                        <v-row justify="center">
                          <v-radio-group
                            v-model="currentBillPlanPeriodicity"
                            row
                          >
                            <v-radio
                              :label="$helpers.string.capitalizeI18N('monthly')"
                              value="monthly"
                            ></v-radio>
                            <v-radio
                              :label="
                                $helpers.string.capitalizeI18N('annually') +
                                ' (-10%)'
                              "
                              value="annually"
                            ></v-radio>
                          </v-radio-group>
                        </v-row>
                        <v-row>
                          Offre {{ currentBillPlan }} :
                          {{ simulatedPrice.billPlan }} € /
                          {{
                            simulatedPrice.periodicity == "monthly"
                              ? $t("month")
                              : $t("year")
                          }}
                        </v-row>
                        <v-row v-if="users.length - 1 >= 1">
                          Utilisateurs supplémentaires ({{ users.length - 1 }})
                          : {{ simulatedPrice.users }} € /
                          {{
                            simulatedPrice.periodicity == "monthly"
                              ? $t("month")
                              : $t("year")
                          }}
                        </v-row>
                        <v-row>
                          <v-divider />
                        </v-row>
                        <v-row>
                          Total:
                          <span class="font-weight-bold ml-1 mr-1"
                            >{{ simulatedPrice.total }} €</span
                          >
                          /
                          {{
                            simulatedPrice.periodicity == "monthly"
                              ? $t("month")
                              : $t("year")
                          }}
                        </v-row>
                        <v-row>
                          Valide jusqu'au
                          {{
                            $helpers.date.getDateDisplay(
                              simulatedPrice.newValidityDateEnd
                            )
                          }}
                        </v-row>
                        <v-row justify="center">
                          <v-btn @click="extendValidity()" color="primary"
                            >{{ $t("extend")
                            }}<v-icon>mdi-chevron-right</v-icon></v-btn
                          >
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item key="ChangePricing" value="ChangePricing">
                  <v-card flat>
                    <v-card-text><v-btn outlined>available soon</v-btn></v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-alert>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BillPlanRequired from "@/components/Admin/BillPlanRequired";

import { loadStripe } from "@stripe/stripe-js/pure";

export default {
  data() {
    return {
      overview: {},
      search: "",
      loadingExtend: false,
      simulatedPrice: null,
      loading: true,
      currentBillPlan: null,
      currentBillPlanPeriodicity: null,
      users: [],
      tab: null,
    };
  },
  components: {
    BillPlanRequired,
  },
  computed: {
    url() {
      return this.overview?.organization?.url;
    },
    referralCode() {
      return this.overview?.organization?.referralCode;
    },
    referralName() {
      return this.overview?.organization?.referralOrganization?.name;
    },
    mollieApiKey() {
      return this.overview?.organization?.mollieApiKey;
    },
    billPlan() {
      return this.overview?.organization?.billPlan;
    },
    billPlanPeriodicity() {
      return this.overview?.organization?.billPlanPeriodicity;
    },
    validityDateEnd() {
      return this.overview?.organization?.validityDateEnd;
    },
    freeTrialValidityDateEnd() {
      return this.overview?.organization?.freeTrialValidityDateEnd;
    },
    isDev() {
      return process.env.NODE_ENV === "development";
    },
    boxType() {
      if (
        this.freeTrialValidityDateEnd &&
        this.$moment(this.freeTrialValidityDateEnd).toDate() > new Date()
      )
        return "success";

      return this.validityDateEnd && new Date(this.validityDateEnd) < new Date()
        ? "error"
        : "success";
    },
  },
  watch: {
    currentBillPlan: async function (val) {
      this.refreshSimulatedPrice(val, this.currentBillPlanPeriodicity);
    },
    currentBillPlanPeriodicity: async function (val) {
      this.refreshSimulatedPrice(this.currentBillPlan, val);
    },
  },
  methods: {
    async refreshSimulatedPrice(billPlan, billPlanPeriodicity) {
      try {
        if (!billPlan || !billPlanPeriodicity) return;

        const { data } = await this.$services.public.simulatePrice(
          billPlan,
          billPlanPeriodicity,
          this.users.length,
          this.validityDateEnd
        );

        this.simulatedPrice = data;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
    },
    async retrieveAll() {
      // overview
      const overview = await this.$services.organization.getOverview();

      this.overview = overview.data;

      // users
      const users = await this.$services.user.getAll();

      this.users = users.data;

      // billplan
      this.currentBillPlanPeriodicity = this.billPlanPeriodicity;
      this.currentBillPlan = this.billPlan;

      this.loading = false;
    },
    async extendValidity() {
      try {
        this.loadingExtend = true;

        const { data } = await this.$services.organization.extend(
          this.currentBillPlan,
          this.currentBillPlanPeriodicity,
          this.$i18n.locale
        );

        console.dir(data);

        if (data.provider === "stripe") {
          // Stripe.js will not be loaded until `loadStripe` is called
          loadStripe.setLoadParameters({ advancedFraudSignals: false });

          const stripe = await loadStripe(
            process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
          ).catch((e) => {
            console.dir("ERROR LOADING STRIPE");
            console.dir(e);
          });

          stripe.redirectToCheckout({ sessionId: data.sessionId });
        } else if (data.provider === "mollie") {
          window.location.href = data.paymentUrl;
        }
      } catch (e) {
        console.dir(e);
        this.$helpers.snackbar.showErrorI18N(e);
      } finally {
        this.loadingExtend = false;
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
  },
  async mounted() {
    this.loading = true;

    await this.retrieveAll();
  },
};
</script>